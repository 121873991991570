.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  
  100%{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wheel[animation="1"] {
  animation: App-logo-spin 20s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1 both;
}


